import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

export default function Footer() {
  // Footer variants for any animation if needed
  const footerVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.footer
      variants={footerVariants}
      initial="initial"
      animate="animate"
      className="bg-starpins-gray text-black p-10 sm:px-4 sm:py-8 w-full"
    >
      <div className="container mx-auto grid grid-cols-4 sm:grid-cols-1 gap-8 sm:gap-4">
        {/* Navigation Links */}
        <div>
          <h4 className="font-bold mb-4">Quick Links</h4>
          <ul>
            <li>
              <NavLink to="/" className="hover:text-starpins-orange">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/ourservices" className="hover:text-starpins-orange">
                Our Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/countries" className="hover:text-starpins-orange">
                Countries
              </NavLink>
            </li>
            <li>
              <NavLink to="/aboutus" className="hover:text-starpins-orange">
                About Us
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div>
          <h4 className="font-bold mb-4">Contact Us</h4>
          <p>Phone: +254 717 848 808</p>
          <p>Email: starpinstravel@gmail .com</p>
          <div className="flex gap-2 sm:grid">
            <div>
              <p>
                1<sup>ST</sup>Floor,
              </p>
              <p>Kampus Towers, Along University Way</p>
            </div>
          </div>
        </div>

        {/* Social Media Links */}
        <div>
          <h4 className="font-bold mb-4">Follow Us</h4>
          {/* Replace with actual social media links */}
          <p>Facebook</p>
          <p>Twitter</p>
          <p>LinkedIn</p>
        </div>

        {/* About Section */}
        <div className="sm:text-start">
          <h4 className="font-bold mb-4">About</h4>
          <p>
            Unlock seamless journeys with our expert services – where travel
            dreams meet hassle-free reality.
          </p>
        </div>
      </div>
      <div className="text-center text-sm my-10">
        <p>&copy; {new Date().getFullYear()} Starpins. All Rights Reserved.</p>
        <p>
          <NavLink to="/terms" className="hover:text-starpins-orange">
            Terms of Service
          </NavLink>{" "}
          |{" "}
          <NavLink to="/privacy" className="hover:text-starpins-orange">
            Privacy Policy
          </NavLink>
        </p>
        <p>
          <span to="/terms" className="hover:text-starpins-orange">
            Build by
          </span>{" "}
          |{" "}
          <a
            href="https://www.stephenmuchara.com/"
            className="hover:text-starpins-orange"
          >
            Stephen Muchara
          </a>
        </p>
      </div>
    </motion.footer>
  );
}
