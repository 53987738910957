import React from "react";
import TopNavigaionBar from "../components/TopNavigaionBar";
import { Helmet } from "react-helmet";
import HeroSection from "../sections/about/HeroSection";
import ContactForm from "../sections/about/ContactForm";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function AboutUs() {
  return (
    <div>
      <Helmet>
        <title>Starpins - AboutUs</title>
      </Helmet>
      <TopNavigaionBar />
      <HeroSection />
      <ContactForm />
      <div>
        <h1 className="text-center font-bold text-2xl my-10">OUR LOCATION</h1>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127642.3210283662!2d36.73642862592669!3d-1.2801285765443247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d34f996221%3A0xbce1b921ba4690ba!2sKampus%20Towers!5e0!3m2!1sen!2ske!4v1705511134391!5m2!1sen!2ske"
          width="100%"
          height="450"
          style={{border:0}}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Our Location"
        ></iframe>
      </div>
      <Footer/>
      <ScrollToTop/>
    </div>
  );
}
