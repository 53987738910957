import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

const SideMenuLink = ({ title }) => {
  return (
    <motion.div className="py-3">
      <NavLink to={`/${title.toLowerCase().replace(/\s+/g, "")}`}>
        {title}
      </NavLink>
    </motion.div>
  );
};

export default SideMenuLink;
