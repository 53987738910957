import React from "react";

export default function HeroSection() {
  return (
    <div className="w-full bg-countries h-[75vh] sm:h-[50vh] bg-center bg-cover bg-no-repeat flex items-center justify-center">
      <div className="grid text-white text-center">
        <h1 className="text-3xl sm:text-2xl font-bold my-5">Countries</h1>
        <p>
          Unlock seamless journeys with our expert services – where travel
          dreams meet hassle-free reality.
        </p>
      </div>
    </div>
  );
}
