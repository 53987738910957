import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Map from "../../images/map.webp";

export default function Tour() {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const itemVariants = {
    hidden: {
      x: 100, // Start from the right, off-screen
      opacity: 0, // Start as invisible
    },
    visible: {
      x: 0, // Move to its original position
      opacity: 1, // Become fully visible
      transition: { type: "spring", duration: 2 },
    },
  };

  return (
    <div ref={ref} className="grid grid-cols-2 sm:grid-cols-1 items-center gap-4 px-16 sm:px-6 sm:mb-10">
      <motion.img
        src={Map}
        alt="Map"
        initial="hidden"
        animate={controls}
        variants={itemVariants}
      />
      <div>
        <motion.h1
          initial="hidden"
          animate={controls}
          variants={itemVariants}
          className="text-2xl sm:text-base font-bold my-2"
        >
          USA
        </motion.h1>
        <motion.h1
          initial="hidden"
          animate={controls}
          variants={itemVariants}
          className="text-4xl sm:text-xl font-bold my-2"
        >
          Tour Guide
        </motion.h1>
        <motion.p
          initial="hidden"
          animate={controls}
          variants={itemVariants}
          className="text-justify"
        >
          Embarking on a journey across the United States becomes an enriched
          experience with the guidance of a seasoned tour guide. From the
          awe-inspiring landscapes of the national parks, where geological
          wonders come to life, to the historical tapestry woven into cities
          like Boston and Philadelphia, a tour guide transforms your exploration
          into an insightful adventure. Immerse yourself in the vibrant culture
          of iconic cities such as New York and New Orleans, where local guides
          unlock the secrets of bustling streets and introduce you to hidden
          gems. Whether your interests lie in nature, history, or urban culture,
          a tour guide in the USA ensures that every step of your journey is
          filled with knowledge, stories, and the joy of discovery.
        </motion.p>
        <motion.button
          initial="hidden"
          animate={controls}
          variants={itemVariants}
          className="p-4 w-1/3 sm:w-3/5 border border-starpins-orange text-black my-8 flex gap-2 items-center"
        >
          <p>Learn More</p>
          <box-icon name="right-arrow-alt"></box-icon>
        </motion.button>
      </div>
    </div>
  );
}
