import React from "react";

export default function ContactForm() {
  return (
    <div className="mx-auto w-[90%] my-6 border grid grid-cols-2 sm:grid-cols-1 text-lg max-h-max]">
      <div className="bg-starpins-orange h-full w-full text-white p-16 sm:p-4 sm:text-sm">
        <h1 className="font-bold my-8">WHY US?</h1>
        <p>
          Starpins is a travel agency that specializes in visa assistance,
          flight reservations, hotel bookings, and expert consultation. They
          guide you through visa application processes, assist with document
          preparation, and provide support. They offer domestic and
          international flight options with various airlines and classes. Their
          hotel reservation services range from budget to luxury and can be
          booked in advance or last-minute. They also offer consultation
          services for destination guidance, itinerary planning, and budgeting.
          Starpins strives to provide comprehensive travel solutions for
          seamless travel planning.
        </p>
        <div className="my-8">
          <div className="my-4">
            <h1>Email</h1>
            <a href="mailto:starpinstravel@gmail.com">starpinstravel@gmail.com</a>
          </div>
          <div className="my-4">
            <h1>Phone Number</h1>
            <p>+254 717 848 808</p>
          </div>
          <div className="my-4">
            <h1>Address</h1>
            <p>1<sup>ST</sup> Floor,</p>
            <p>Kampus Towers, Along University Way</p>
          </div>
        </div>
      </div>
      <div className="h-full w-full text-black p-16 sm:p-4">
        <h1 className="font-bold my-8">GET IN TOUCH WITH US</h1>
        <div className="my-8">
          <div className="my-4">
            <h1>Name</h1>
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                placeholder="First Name"
              />
              <input
                type="text"
                className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="my-4">
            <h1>Phone Number</h1>
            <input
              type="text"
              className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              placeholder="245-786-555"
            />
          </div>
          <div className="my-4">
            <h1>Email</h1>
            <input
              type="email"
              className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              placeholder="aqw@qwa.com"
            />
          </div>
          <div className="my-4">
            <h1>Message</h1>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              className="bg-gray-50 p-2 px-6 my-4 w-full shadow-md border border-gray-50 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              placeholder="Type Message .."
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}
