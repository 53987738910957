import React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../sections/countries/HeroSection";
import TopNavigaionBar from "../components/TopNavigaionBar";
import Country from "../sections/countries/Country";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Countries() {
  return (
    <div>
      <Helmet>
        <title>Starpins - Countries</title>
      </Helmet>
      <TopNavigaionBar />
      <HeroSection />
      <Country />
      <Footer />
      <ScrollToTop/>
    </div>
  );
}
