import React from "react";

export default function Feature({ title, content, thumbnail }) {
  return (
    <div className="hover:shadow-xl shadow-black cursor-pointer">
      <div
        className={`${thumbnail} rounded-3xl h-[25vh] sm:h-[20vh] bg-cover bg-center px-10 py-10 text-white flex flex-col justify-center items-center`}
      >
        <div className="grid items-center justify-center my-8">
        <h1 className="text-xl font-bold sm:text-base sm:my-2 my-4">{title}</h1>
        </div>
      </div>
    </div>
  );
}
