import React from "react";
import TopNavigaionBar from "../components/TopNavigaionBar";
import { Helmet } from "react-helmet";
import HeroSection from "../sections/ourServices/HeroSection";
import Offers from "../sections/ourServices/Offers";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function OurServices() {
  return (
    <div>
      <Helmet>
        <title>Starpins - Our Services</title>
      </Helmet>
      <TopNavigaionBar />
      <HeroSection />
      <Offers />
      <Footer/>
      <ScrollToTop/>
    </div>
  );
}
