import React from "react";
import HeroImage from "../../images/heroimage.webp";
import MouseScroll from "../../components/mouse";
import { NavLink } from "react-router-dom";

export default function HeroSection() {
  return (
    <div className="grid grid-cols-10 sm:grid-cols-1 h-screen align-middle items-center sm:px-6 bg-starpins-gray">
      <div className="col-span-3 sm:col-span-1 py-6 sm:pt-14 pl-16 sm:pl-0 bg-star">
        <h1 className="text-2xl sm:text-base font-bold my-4 sm:my-6 text-starpins-orange">Discover</h1>
        <div className="flex items-center gap-2">
          <hr className="border border-starpins-green w-1/4 sm:w-1/5" />
          <p className="text-xs text-starpins-green">VISA & JOB APPLICATION</p>
          <hr className="border border-starpins-green w-1/4 sm:w-1/5" />
        </div>
        <h2 className="text-4xl sm:text-xl font-bold uppercase my-4 mb-10 w-[100vh] sm:w-full z-30 relative tracking-normal leading-snug">
          Your Gateway t<span className="text-white sm:text-black">o Global</span> Exploration
          an<span className="text-white sm:text-black">d Boundless</span> Opportunities
        </h2>
        <NavLink to="/ourservices" className="p-4 w-full bg-starpins-orange text-white hover:bg-starpins-green hover:text-white">
          Learn More
        </NavLink>
      </div>
      <div className="col-span-7 sm:col-span-1 flex justify-center items-center relative">
        <img
          src={HeroImage}
          alt={HeroImage}
          className="object-cover max-h-screen w-full h-full shadow-left"
        />
        <div className="absolute bottom-4 right-10 sm:left-5 sm:right-0 text-white font-bold">
          <div className="flex gap-2">
            <p>Follow Us:</p>
            <box-icon
              type="logo"
              name="facebook-circle"
              color="white"
            ></box-icon>
            <box-icon name="instagram-alt" type="logo" color="white"></box-icon>
            <box-icon
              name="linkedin-square"
              type="logo"
              color="white"
            ></box-icon>
            <box-icon name="tiktok" type="logo" color="white"></box-icon>
          </div>
        </div>
      </div>
      <MouseScroll />
    </div>
  );
}
