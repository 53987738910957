import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import WebLogo from "../images/starpins_logo.webp";
import SideMenu from "./SideMenu";

export default function TopNavigaionBar() {
  const [showVisaSubMenu, setShowVisaSubMenu] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Define the style variants
  const navbarVariants = {
    scrolled: {
      backgroundColor: "#F7F7F7", // White background
      color: "#000000", // Black text
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", // Box shadow
      transition: {
        type: "tween", // Smooth transition
        duration: 0.3, // Transition duration
      },
    },
    notScrolled: {
      backgroundColor: "rgba(255, 255, 255, 0)", // Transparent background
      color: "#ffffff", // White text
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
  };

  const submenuVariants = {
    initial: {
      opacity: 0,
      y: -10,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      y: -10,
      transition: {
        when: "afterChildren",
      },
    },
  };

  const itemVariants = {
    initial: {
      opacity: 0,
      y: -10,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div>
      {/* Mobile Menu */}
      <button
        onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
        className="sm:inline-block hidden  sm:fixed top-5 left-5 z-50 bg-starpins-gray shadow-md shadow-gray-400 rounded-full p-2 h-12 w-12"
      >
        <box-icon name="menu-alt-left" size="md"></box-icon>
      </button>
      {/* SideMenu Component */}
      <SideMenu
        isOpen={isSideMenuOpen}
        onClose={() => setIsSideMenuOpen(false)}
      />
      <motion.div
        variants={navbarVariants}
        animate={isScrolled ? "scrolled" : "notScrolled"}
        className="fixed top-0 right-0 left-0 z-40"
      >
        <div className="flex justify-between items-center px-16 py-6 h-24 sm:hidden">
          {/* Logo */}
          <NavLink to="/" className="hover:text-starpins-orange">
            <img src={WebLogo} alt="Web Logo" className="h-16 sm:h-10" />
          </NavLink>

          {/* Navigation Links */}
          <div className="flex gap-16 px-4 uppercase items-center">
            <NavLink
              to="/"
              className="hover:text-starpins-orange"
              activeClassName="active"
            >
              Home
            </NavLink>
            <NavLink
              to="/ourservices"
              className="hover:text-starpins-orange"
              activeClassName="active"
            >
              Our Services
            </NavLink>

            {/* Visa with Submenu */}
            <div
              onMouseEnter={() => setShowVisaSubMenu(true)}
              onMouseLeave={() => setShowVisaSubMenu(false)}
              className="relative"
            >
              <NavLink
                className="hover:text-starpins-orange"
                activeClassName="active"
              >
                Visa
              </NavLink>
              {showVisaSubMenu && (
                <motion.div
                  className="absolute top-5 left-0 w-40 bg-starpins-gray text-black mt-1 py-2 rounded shadow-lg"
                  variants={submenuVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <motion.li variants={itemVariants} className="list-none">
                    <NavLink
                      to="/visa/business"
                      className="block px-4 hover:bg-orange-100 py-4"
                    >
                      Business Visa
                    </NavLink>
                  </motion.li>
                  <motion.li variants={itemVariants} className="list-none">
                    <NavLink
                      to="/visa/tourist"
                      className="block px-4 hover:bg-orange-100 py-4"
                    >
                      Tourist Visa
                    </NavLink>
                  </motion.li>
                  <motion.li variants={itemVariants} className="list-none">
                    <NavLink
                      to="/visa/student"
                      className="block px-4 hover:bg-orange-100 py-4"
                    >
                      Student Visa
                    </NavLink>
                  </motion.li>
                </motion.div>
              )}
            </div>

            <NavLink
              to="/countries"
              className="hover:text-starpins-orange"
              activeClassName="active"
            >
              Countries
            </NavLink>
            <NavLink
              to="/aboutus"
              className="hover:text-starpins-orange"
              activeClassName="active"
            >
              About Us
            </NavLink>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
