import React from "react";

export default function HeroSection() {
  return (
    <div className="w-full bg-touristVisaCard h-[75vh] sm:h-[50vh] bg-center bg-cover bg-no-repeat flex items-center justify-center">
      <div className="grid text-white text-center">
        <h1 className="text-3xl sm:text-2xl font-bold my-5">Tourist Visa</h1>
        <p>Your Gateway to Unforgettable Memories</p>
      </div>
    </div>
  );
}
