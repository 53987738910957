import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountryCard from "../../components/CountryCard";

export default function Opportunities() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const countries = [
    { bgImage: "bg-lasVegas", country: "USA" },
    { bgImage: "bg-dubai", country: "United Arab Emirates"},
    { bgImage: "bg-canada", country: "Canada"},
    { bgImage: "bg-britain", country: "UK"},
  ];

  return (
    <div className="h-[75vh] sm:h-[80vh] bg-starpins-orange my-6 p-8 sm:p-6">
      <p className="uppercase">Uncover Opportunities</p>
      <h1 className="text-4xl sm:text-xl my-3 font-bold">Job Applications</h1>
      <Slider {...sliderSettings}>
        {countries.map((card, i) => (
          <div key={i} className="p-4 sm:p-2">
            <CountryCard
              bgImage={card.bgImage}
              country={card.country}
              state={card.state}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
