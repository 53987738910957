import React from "react";
import { motion, useAnimation } from "framer-motion";
import Gallery from "../../images/about_gallery.webp";
import { useInView } from 'react-intersection-observer';
import { NavLink } from "react-router-dom";

export default function About() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };
  return (
    <motion.div
      className="grid grid-cols-2 sm:grid-cols-1 gap-10 my-24 sm:my-8 items-center px-16 sm:px-6"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      <img src={Gallery} alt={Gallery} className="sm:hidden" />
      <div>
        <h1 className="text-xl text-blue-500 font-bold">About Us</h1>
        <h1 className="my-6 text-3xl sm:text-xl">
          <strong>Seamless</strong> Visas, <strong>Effortless</strong> Journeys:
          Where We Transform Your Travel Dreams into{" "}
          <strong>Effortless Realities</strong>
        </h1>
        <p className="text-justify mb-10">
          Embark on stress-free travels with us! From securing hassle-free visas
          to ensuring smooth journeys, we specialize in making every step of
          your travel experience enjoyable and effortless. Let us handle the
          details, so you can focus on creating lasting memories. Your adventure
          begins with simplicity and ease.
        </p>
        <NavLink to="/aboutus" className="p-4 w-full bg-starpins-orange text-white hover:bg-starpins-green hover:text-white">
          Learn More
        </NavLink>
      </div>
      <img src={Gallery} alt={Gallery} className="hidden sm:inline-block" />
    </motion.div>
  );
}
