import "./App.css";
import { useEffect } from "react";
import "boxicons";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import OurServices from "./pages/OurServices";
import AboutUs from "./pages/AboutUs";
import Countries from "./pages/Countries";
import BusinessVisa from "./pages/BusinessVisa";
import TouristVisa from "./pages/TouristVisa";
import StudentVisa from "./pages/StudentVisa";

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation(); // Use useLocation hook to get the current pathname

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, [pathname]); // This effect runs every time the pathname changes

    return null; // This component does not render anything
  }
  return (
    <div className="font-Montserrat">
      <Router>
        <ScrollToTop />{" "}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/ourservices" element={<OurServices />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/visa/business" element={<BusinessVisa />} />
          <Route path="/visa/tourist" element={<TouristVisa />} />
          <Route path="/visa/student" element={<StudentVisa />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
