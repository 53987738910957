import React from "react";
import VisaCard from "../../images/studentThumbnail.webp";

export default function Content() {
  return (
    <div className="px-16 my-16 sm:px-6">
      <h1 className="font-bold text-3xl my-4">Student Visa</h1>
      <div className="grid grid-cols-2 sm:grid-cols-1 my-8 sm:my-0">
        <img
          src={VisaCard}
          alt="Business Visa Card"
          className="w-4/5 sm:w-full my-4"
        />
        <div>
          <h1 className="text-3xl sm:text-2xl">
            The First Step to Your Academic Aspirations
          </h1>
          <div>
            <div className="grid my-6 gap-2">
              <p>
                Welcome to Starpins Travel Agency – your ultimate partner in
                unlocking the doors to academic success and personal growth
                abroad! We specialize in seamlessly guiding students through the
                exciting journey of gaining admission to high schools, colleges,
                and universities around the globe. With a vast network of over
                600 institutions in countries like the USA, UK, Australia,
                Canada, New Zealand, and Ireland, we ensure your educational
                dreams become a reality.
              </p>
              <p className="my-6">
                Embark on your academic adventure with us, where we guarantee a
                smooth and supportive process for securing your student visa.
                From the moment you sign those enrollment papers to settling
                into the rhythm of your new academic life, we're right by your
                side, ensuring every step is a breeze. Plus, for those with
                stellar grades, exciting scholarship opportunities await!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <h1 className="font-bold text-3xl sm:text-2xl">
        Benefits of a Starpins Student Visa
      </h1>
      <ul className="list-disc pl-6 my-4">
        <li>
          World-Class Education: Gain access to prestigious universities and
          colleges worldwide, offering superior learning experiences.
        </li>
        <li>
          Cultural Exploration: Immerse yourself in diverse cultures, enhancing
          your global awareness and understanding.
        </li>
        <li>
          Language Mastery: Studying abroad offers a unique opportunity to
          improve or learn new languages in an immersive environment.
        </li>
        <li>
          Personal Evolution: Embark on a journey of self-discovery and
          independence, shaping you into a global citizen.
        </li>
        <li>
          Career Advancement: A degree from a renowned international institution
          can significantly boost your career prospects.
        </li>
      </ul> */}
    </div>
  );
}
