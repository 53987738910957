import React from 'react'
import TopNavigaionBar from '../components/TopNavigaionBar'
import HeroSection from '../sections/studentVisa/HeroSection'
import Content from '../sections/studentVisa/Content'
import { Helmet } from 'react-helmet'
import About from '../sections/studentVisa/About'
import Footer from '../components/Footer'
import ScrollToTop from '../components/ScrollToTop'

export default function StudentVisa() {
  return (
    <div>
      <TopNavigaionBar/>
      <Helmet>
        <title>Starpins - Student Visa</title>
      </Helmet>
      <HeroSection/>
      <Content/>
      <About/>
      <Footer/>
      <ScrollToTop/>
    </div>
  )
}
