import React from "react";

export default function CountryCard({country,bgImage}) {
  return (
    <div className={`country-card rounded-3xl ${bgImage} h-[50vh] relative`}>
      <div className="absolute top-2 right-2">
        <div className="h-8 w-8 bg-gray-600 rounded-full flex items-center justify-center">
          <box-icon name="heart" color="white"></box-icon>
        </div>
      </div>
      <div className="absolute m-5 bottom-4 left-0 right-0 text-white">
        <div className="flex gap-2 my-1">
          <svg
            width="19"
            height="23"
            viewBox="0 0 19 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 9.5C18 10.5421 17.5154 11.8523 16.6543 13.3123C15.8072 14.7485 14.6634 16.2179 13.4973 17.5511C12.3346 18.8803 11.1688 20.0523 10.2922 20.8931C9.98865 21.1842 9.7205 21.435 9.5 21.638C9.27951 21.435 9.01135 21.1842 8.70783 20.8931C7.8312 20.0523 6.66543 18.8803 5.50268 17.5511C4.33657 16.2179 3.19277 14.7485 2.34572 13.3123C1.48462 11.8523 1 10.5421 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5ZM19 9.5C19 14.7467 9.5 22.9839 9.5 22.9839C9.5 22.9839 0 14.7467 0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5ZM14.3226 8.27412C14.3226 10.9376 12.1634 13.0967 9.49998 13.0967C6.83655 13.0967 4.6774 10.9376 4.6774 8.27412C4.6774 5.61068 6.83655 3.45154 9.49998 3.45154C12.1634 3.45154 14.3226 5.61068 14.3226 8.27412ZM15.3226 8.27412C15.3226 11.4898 12.7157 14.0967 9.49998 14.0967C6.28426 14.0967 3.6774 11.4898 3.6774 8.27412C3.6774 5.0584 6.28426 2.45154 9.49998 2.45154C12.7157 2.45154 15.3226 5.0584 15.3226 8.27412Z"
              fill="white"
            />
          </svg>
          <p>{country}</p>
        </div>
      </div>
    </div>
  );
}
