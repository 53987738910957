import React from "react";
import Helmet from "react-helmet";
import HeroSection from "../sections/homepage/HeroSection";
import TopNavigaionBar from "../components/TopNavigaionBar";
import About from "../sections/homepage/About";
import Opportunities from "../sections/homepage/Opportunities";
import Tour from "../sections/homepage/Tour";
import Testimonials from "../sections/homepage/Testimonials";
import Blogs from "../sections/homepage/Blogs";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Homepage() {
  return (
    <div className="overflow-y-hidden">
      <Helmet>
        <title>Starpins-Homepage</title>
      </Helmet>
      <TopNavigaionBar />
      <HeroSection />
      <About />
      <Opportunities />
      <Tour />
      <Testimonials />
      <Blogs />
      <Footer/>
      <ScrollToTop/>
    </div>
  );
}
