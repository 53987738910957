import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const variants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  return isVisible ? (
    <motion.button
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
      onClick={scrollToTop}
      className="scroll-to-top-button w-14 h-14 rounded-full fixed bottom-0 right-0 bg-starpins-orange m-3 shadow-lg shadow-gray-500 z-30"
    >
      <box-icon type="solid" name="up-arrow" color="white"></box-icon>
    </motion.button>
  ) : null;
};

export default ScrollToTop;
