import React from "react";

export default function OffersCard({ title, content, thumbnail }) {
  return (
    <div className="hover:shadow-xl shadow-black cursor-pointer">
      <div
        className={`${thumbnail} rounded-3xl h-[70vh] sm:h-[65vh] bg-cover bg-center px-10 py-10 text-white flex flex-col justify-end`}
      >
        <div className="grid h-[45vh] sm:h-[40vh] items-start justify-start my-8">
        <h1 className="text-2xl font-bold sm:text-xl sm:my-2 my-4">{title}</h1>
        <p>{content}</p>
        </div>
      </div>
    </div>
  );
}
