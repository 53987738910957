import React from "react";

export default function BlogPreview({
  title,
  category,
  content,
  author,
  postedDate,
  thumbnail,
  alt
}) {
  return (
    <div className="grid grid-cols-2 items-center gap-6">
      <div>
        <p>{category}</p>
        <h1 className="font-bold text-4xl sm:text-sm my-3">{title}</h1>
        <p className="sm:text-xs">{content}</p>
        <p className="font-bold my-2 sm:text-xs">
          By {author} Posted on {postedDate}
        </p>
        <button className="p-4 sm:px-2 sm:py-3 w-1/3 sm:w-full border border-starpins-orange text-black my-8 flex gap-2 items-center">
          <p>Learn More</p>
          <box-icon name="right-arrow-alt"></box-icon>
        </button>
      </div>
      <img
        className="mx-auto" // Center horizontally
        src={process.env.PUBLIC_URL + thumbnail}
        alt={alt}
      />
    </div>
  );
}
