import React from "react";
import OffersCard from "../../components/OffersCard";

export default function Offers() {
  return (
    <div className="px-16 sm:px-6 my-14">
      <div className="text-center mb-8">
        <h1 className="text-center font-bold text-3xl sm:text-2xl my-6">
          WHAT WE OFFER
        </h1>
        <p>
          Starpins is a travel agency that specializes in visa assistance,
          flight reservations, hotel bookings, and expert consultation. We guide
          you through visa application processes, assist with document
          preparation, and provide support. We offer domestic and international
          flight options with various airlines and classes. Our hotel
          reservation services range from budget to luxury and can be booked in
          advance or last-minute. We also offer consultation services for
          destination guidance, itinerary planning, and budgeting. Starpins
          strives to provide comprehensive travel solutions for seamless travel
          planning.
        </p>
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-1 gap-6 my-20">
        <OffersCard
          thumbnail="bg-visa_consultation"
          title="Visa Voyage: Unlock World Wonders"
          content="Ever wondered about the magic behind global adventures? At Starpins, we offer you a
          complimentary visa consultancy service because we believe in making your travel dreams come
          true with professional guidance."
        />
        <OffersCard
          thumbnail="bg-visa_application"
          title="Visa Bliss: Your Gateway to Hassle-Free Applications"
          content="Navigate the visa application process effortlessly with our comprehensive services. We work
          closely with you to ensure that your application is not just complete but accurate, adhering to all
          relevant regulations. Your journey begins with a stress-free visa application experience."
        />
        <OffersCard
          thumbnail="bg-flight_booking"
          title="Flight Fantasia: Soaring Beyond Boundaries"
          content="Stay ahead with our real-time flight information. We keep you informed about schedules, delays,
          cancellations, and more by staying connected with airlines and utilizing cutting-edge flight
          tracking tools. Your voyage starts with the latest in-flight updates."
        />
        <OffersCard
          thumbnail="bg-hotel_reservations"
          title="Hotel Harmony: Where Comfort Meets Convenience"
          content="Relax in style with our hotel booking services. Stay abreast of the ever-evolving hotel industry
          regulations and policies, ensuring a positive experience for our customers. Your comfort is our
          commitment."
        />
        <OffersCard
          thumbnail="bg-job_searching"
          title="Career Catalyst: Navigating Job Opportunities"
          content="Revolutionizing job searching, we’ve streamlined the process for efficiency and
          user-friendliness. Stay in the loop with the latest job market trends, openings, and opportunities.
          Your career journey begins with us."
        />
        <OffersCard
          thumbnail="bg-hosting"
          title="Hosting Haven: Your Home Abroad"
          content="Transition seamlessly into your dream destination with our one-month hosting service. We
          connect you with hosts in your dream countries, ensuring a smooth start to your new life. Your
          dream living experience awaits. Embark on your journey with Starpins - Where Your Adventure Begins"
        />
      </div>
    </div>
  );
}
