import React from "react";

export default function HeroSection() {
  return (
    <div className="w-full bg-our-services h-[75vh] sm:h-[45vh] bg-center bg-cover bg-no-repeat flex items-center justify-center">
      <div className="grid text-white text-center">
        <h1 className="text-3xl sm:text-2xl font-bold my-5">OUR SERVICES</h1>
        <p className="px-16 sm:px-4">
          Welcome to Starpins Travel Agency, your passport to seamless global
          exploration! At Starpins, we believe that everyone deserves expert
          guidance when embarking on their international journey. Discover our
          array of services tailored to make your travel aspirations a reality.
        </p>
      </div>
    </div>
  );
}
