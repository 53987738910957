import React from "react";
import BlogPreview from "../../components/BlogPreview";
import blogsData from "../../data/Blogs.json";

export default function Blogs() {
  return (
    <div className="px-16 my-16 sm:px-6">
      <h1 className="text-center text-3xl font-bold my-6">Latest Blogs</h1>
      {blogsData.Blogs.map((blog, index) => (
        <div key={index} className="my-8">
          <BlogPreview
            title={blog.title}
            category={blog.category}
            content={blog.content}
            author={blog.author}
            postedDate={blog.postedDate}
            thumbnail={blog.thumbnail}
            alt={blog.alt}
          />
        </div>
      ))}
    </div>
  );
}
