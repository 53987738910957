import React from "react";
import Travel from "../../images/travel.webp";

export default function Content() {
  return (
    <div className="px-16 my-16 sm:px-6">
      <h1 className="font-bold text-3xl sm:text-2xl my-4">
        Starpins Tourist Visa
      </h1>
      <div className="grid grid-cols-2 sm:grid-cols-1 my-8 sm:my-0">
        <img src={Travel} alt="Travel Visa Card" className="w-4/5 sm:w-full my-4" />
        <div>
          <h1 className="text-3xl">Your Gateway to Unforgettable Memorie</h1>
          <div>
            <p className="my-6">
              Starpins is dedicated to making your travel dreams a reality. Our
              tourist visas are specially designed for those who wish to explore
              new countries for leisure, vacation, or to experience the wonders
              of the world. Whether it's sightseeing, indulging in diverse
              cultures, or relaxing in exotic locations, we've got you covered.
            </p>
            <h1 className="font-bold text-3xl sm:text-2xl">
              Benefits of a Starpins Tourist Visa
            </h1>
            <ul className="list-disc pl-6 my-4">
              <li>
                Cultural Immersion: Dive into new cultures, visit historic
                landmarks, and enjoy authentic culinary experiences.
              </li>
              <li>
                Personal Growth: Traveling broadens your perspective, offering
                invaluable life lessons and personal development.
              </li>
              <li>
                Ultimate Relaxation: Our tourist visas are your ticket to unwind
                and recharge, away from the hustle of everyday life..
              </li>
              <li>
                Mental Wellness: Embrace the joys of travel to enhance your
                mental health, reducing stress and rejuvenating your spirit.
              </li>
              <li>
                Language and Skills: Use your travels to hone your language
                skills and learn new ones in a natural setting.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h1 className="font-bold text-3xl sm:text-2xl">Explore with Starpins</h1>
      <p className="my-6">
        With Starpins, you’re not just traveling; you’re collecting memories.
        From dining on a serene beach with the backdrop of a clear blue sky to
        wandering through ancient streets steeped in history, our tourist visas
        unlock a world of possibilities
      </p>

      <h2 className="font-bold text-3xl sm:text-2xl">Starpins Experiences</h2>
      <ul className="list-disc pl-6 my-4">
        <li>
          Beachside Bliss: Imagine dining at a table on a pristine beach, the
          ocean stretching endlessly before you under a blue sky..
        </li>
        <li>
          Historical Treasures: Step back in time as you explore ancient ruins
          and historical sites, each with a story to tell.
        </li>
        <li>
          Urban Adventures: Discover the heartbeat of the world's most vibrant
          cities, from bustling markets to iconic skyscrapers.
        </li>
        <li>
          Natural Wonders: Stand in awe of nature's creations, from towering
          mountains to deep, tranquil lakes.
        </li>
      </ul>
      <p>
        In essence, a Starpins Tourist Visa is more than just a travel document;
        it’s a passport to adventures and experiences that enrich your life and
        create lasting memories.
      </p>
    </div>
  );
}
