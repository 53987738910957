import React from "react";
import { motion } from "framer-motion";
import Sydney from "../../images/sydney.webp";
import Toronto from "../../images/toronto.webp";
import Cochem from "../../images/cochem.webp";
import Usa from "../../images/usa.webp";
import NewZealand from "../../images/new-zealand.webp";
import Ireland from "../../images/ireland.webp";
import Uk from "../../images/uk.webp";

const Country = () => {
  const cards = [
    {
      country: "USA",
      title: "The Land of Dreams and Possibilities",
      description:
        "Embark on the American dream – work, tour, or study in the USA. Experience the melting pot of cultures, iconic landmarks, and a dynamic lifestyle. Unleash your potential in the land of opportunities, where every day is a step towards greatness.",
      image: Usa,
    },
    {
      country: "Canada",
      title: "Unlock Your Potential in the True North",
      description:
        "Canada beckons with its diverse landscapes and thriving job market. Work, tour, or study amidst breathtaking scenery and enjoy the warm Canadian hospitality. Whether it's the bustling cities or the serene wilderness, Canada is your gateway to endless opportunities.",
      image: Toronto,
    },
    {
      country: "Australia",
      title: "Beyond the Classroom, Endless Opportunities",
      description:
        "Australia stands as a global education hub, offering a diverse range of courses and world-renowned institutions. Study in a country that values innovation and provides practical, industry-focused learning. Beyond the classroom, Australia offers a dynamic lifestyle and a multicultural society, enriching your overall educational experience.",
      image: Sydney,
    },
    {
      country: "UK",
      title: "Blend Tradition with Innovation",
      description:
        "In the UK, history meets modernity. Work, tour, or study in a country where castles stand next to skyscrapers. Immerse yourself in the rich cultural tapestry while gaining world-class education or exploring the bustling urban scenes.",
      image: Uk,
    },
    {
      country: "New Zealand",
      title: "Elevate Your Education, Embrace Nature",
      description:
        "Choose New Zealand for a world-class education surrounded by breathtaking landscapes. Immerse yourself in a supportive academic environment while enjoying the Kiwi way of life. From cutting-edge research facilities to outdoor adventures, New Zealand offers a unique blend of education and nature.",
      image: NewZealand,
    },
    {
      country: "Ireland",
      title: "Academic Excellence in a Charming Setting",
      description:
        "Ireland, with its rich literary history and top-tier universities, provides a welcoming environment for international students. Dive into academic excellence, vibrant cultural experiences, and a close-knit community. Your educational journey in Ireland is not just about textbooks; it's a holistic experience.",
      image: Ireland,
    },
    {
      country: "Schengen Countries",
      title: "Austria to Sweden: Your Touring and Working Haven",
      description:
        "Schengen Countries - Austria to Sweden: Your Touring and Working Haven From the enchanting cities of Austria to the picturesque landscapes of Sweden, explore the charm and diversity of Europe. Work in dynamic environments, soak in cultural richness, and tour historic landmarks. Each country, from Belgium, Croatia, Czechia, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Slovakia, Slovenia, Spain, to Sweden offers a unique blend of professional opportunities and unforgettable experiences. Embark on a journey that transcends borders and opens doors to a world of exploration and career growth. Discover your passion, work in exciting industries, and explore the wonders of Europe. Your adventure starts here",
      image: Cochem,
    },
  ];

  // Animation variants
  const imageVariants = {
    hover: { y: -50 }, // Adjust the value to control the slide up distance
    initial: { y: 0 },
  };

  // Function to determine if a card needs overflow control
  const needsOverflow = (description) => description.length > 300;

  return (
    <div className="grid grid-cols-3 gap-5 sm:grid-cols-1 px-16 sm:px-6 my-16">
      {cards.map((card, index) => (
        <div
          key={index}
          className="cursor-pointer relative h-[70vh] w-full mx-auto sm:my-6 bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl"
        >
          <div className="md:flex">
            <div className="flex items-center justify-center h-full">
              <motion.img
                className="h-48 w-full object-cover md:h-full sm:w-full"
                src={card.image}
                alt={card.title}
                variants={imageVariants}
                initial="initial"
              />
            </div>
            <motion.div
              className="p-8 absolute z-10 bottom-0 h-[40vh] left-0 bg-white overflow-y-auto"
              variants={imageVariants}
              initial="initial"
              whileHover="hover"
            >
              <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                {card.country}
              </div>
              <div className="font-bold my-2 text-base">{card.title}</div>
              <p
                className={`mt-2 text-gray-500 ${
                  needsOverflow(card.description) ? "overflow-y-scroll" : ""
                }`}
              >
                {card.description}
              </p>
            </motion.div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Country;
