import React from "react";
import Feature from "../../components/Feature";

export default function About() {
  return (
    <div className="px-16 sm:px-6">
      <h1 className="text-center text-4xl font-bold my-6">Our services</h1>
      <div className="grid grid-cols-4 sm:grid-cols-2 gap-6 my-20">
        <Feature thumbnail="bg-visa_consultation" title="Career councelling" />
        <Feature
          thumbnail="bg-visa_application"
          title=" School application assistance"
        />
        <Feature
          thumbnail="bg-flight_booking"
          title=" Scholarship opportunities"
        />
        <Feature thumbnail="bg-hotel_reservations" title=" IELTS training" />
        <Feature thumbnail="bg-job_searching" title=" Visa application" />
        <Feature thumbnail="bg-hosting" title=" Health Insurance" />
        <Feature thumbnail="bg-hosting" title="Flight Booking" />
        <Feature thumbnail="bg-hosting" title="Airport pickup/drop offs" />
        <Feature thumbnail="bg-hosting" title="Regular follow ups" />
      </div>
      <div className="container">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-16 sm:p-4">
            <h1 className="text-2xl font-bold text-center mb-4">
              Welcome to Starpins Student Adventures!
            </h1>
            <p className="text-base text-gray-600">
              <span className="font-semibold">Dynamic Learning Vibes:</span>{" "}
              Dive into Interactive Education - Immerse yourself in vibrant
              learning spaces where collaboration and innovation take the lead.
              At Starpins, education isn't just about books; it's about dynamic
              engagement, ensuring every learning experience is a thrilling
              journey.
            </p>
            <p className="mt-4 text-base text-gray-600">
              <span className="font-semibold">Global Networks Galore:</span>{" "}
              Connect, Expand, Thrive - Build a global tapestry of lifelong
              connections with peers from every nook and cranny of the world.
              Your Starpins student journey extends beyond borders, turning your
              time abroad into an opportunity to grow both personally and
              professionally.
            </p>
            <p className="mt-4 text-base text-gray-600">
              <span className="font-semibold">Hands-On Excellence:</span>{" "}
              Intern, Explore, Excel - Embark on a hands-on academic adventure
              with internships, research projects, and experiential learning.
              Your academic journey with Starpins isn't confined to classrooms;
              it's a pathway to real-world experiences that enrich and
              transform.
            </p>
            <p className="mt-4 text-base text-gray-600">
              <span className="font-semibold">Culture Quest:</span> Clubs,
              Events, Heritage Hues - Join vibrant student clubs, immerse
              yourself in cultural events, and embrace the richness of your host
              country's heritage. At Starpins, we believe in creating global
              citizens through active cultural engagement and celebration.
            </p>
            <p className="mt-4 text-base text-gray-600">
              <span className="font-semibold">In essence,</span> a Starpins
              Student Visa is not just a ticket to study abroad; it's your
              magical key unlocking a world of educational and personal growth
              opportunities. Let's shape your future together – the Starpins
              way, where education meets excitement!
            </p>
          </div>
        </div>
      </div>

      <p className="my-16">
        Join Starpins Travel Agency, where your educational journey is not just
        a path to success but an exhilarating ride filled with possibilities!
      </p>
    </div>
  );
}
