import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "../../components/TestimonialCard";
import testimonialsData from "../../data/Testimonials.json";
import RightArrowIcon from "../../components/RightArrowIcon";
import LeftArrowIcon from "../../components/LeftArrowIcon";

function SampleNextArrow(props) {
  const { onClick } = props;
  const [rightPosition, setRightPosition] = useState("-40px");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        // Adjust the 600px breakpoint as needed
        setRightPosition("-10px");
      } else {
        setRightPosition("-40px");
      }
    }

    // Set the position on initial render
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <RightArrowIcon
      style={{
        display: "block",
        position: "absolute",
        top: "50%",
        right: rightPosition,
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  const [leftPosition, setLeftPosition] = useState("-40px");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 600) {
        // You can adjust the 600px breakpoint as needed
        setLeftPosition("-10px");
      } else {
        setLeftPosition("-40px");
      }
    }

    // Set the position on initial render
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <LeftArrowIcon
      style={{
        display: "block",
        position: "absolute",
        top: "50%",
        left: leftPosition,
        transform: "translateY(-50%)",
      }}
      onClick={onClick}
    />
  );
}

export default function Testimonials() {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="px-16 -mt-14 sm:-mt-8 sm:px-4">
      <p>Why Choose Us</p>
      <h1 className="font-bold text-3xl sm:text-2xl">Testimonials</h1>
      <Slider {...sliderSettings} className="my-6">
        {testimonialsData.Testimonials.map((testimonial, index) => (
          <div key={index} className="p-4 sm:p-2">
            <TestimonialCard
              message={testimonial.message}
              author={testimonial.author}
              occupation={testimonial.occupation}
              authorInitial={testimonial.author.charAt(0)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
