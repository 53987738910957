import React from 'react'
import TopNavigaionBar from '../components/TopNavigaionBar'
import HeroSection from '../sections/businessVisa/HeroSection'
import Content from '../sections/businessVisa/Content'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import ScrollToTop from '../components/ScrollToTop'

export default function BusinessVisa() {
  return (
    <div>
      <TopNavigaionBar/>
      <Helmet>
        <title>Starpins - Business Visa</title>
      </Helmet>
      <HeroSection/>
      <Content/>
      <Footer/>
      <ScrollToTop/>
    </div>
  )
}
