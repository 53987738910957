import React from "react";
import VisaCard from "../../images/businessVisaCard.webp";

export default function Content() {
  return (
    <div className="px-16 my-16 sm:px-6">
      <h1 className="font-bold text-3xl sm:text-2xl my-4">
        Starpins Business Visa
      </h1>
      <div className="grid grid-cols-2 sm:grid-cols-1 my-8 sm:my-0 items-start">
        <img
          src={VisaCard}
          alt="Business Visa Card"
          className="w-4/5 sm:w-full my-4"
        />
        <div>
          <h1 className="text-3xl">Enhancing Global Business Opportunities</h1>
          <div>
            <div className="grid">
              <p className="my-6">
                Welcome to the gateway of business exploration! The Business
                Visitor Visa, meticulously crafted for savvy professionals, is
                your ticket to engaging in short-term business ventures in
                Canada. Whether you're diving into business gatherings, honing
                skills in training sessions, or uncovering Canadian business
                opportunities, this visa is your key.
              </p>
              <p>
                Our Business Visa Services extend to powerhouse destinations,
                including the USA, Canada, the UK, and all 27 Schengen
                Countries. Picture this visa as your golden opportunity – it not
                only opens doors to a plethora of business possibilities but
                also combines convenience, networking, and the added perk of an
                extended stay. Elevate your business game with a visa that makes
                work feel like an exciting adventure
              </p>
            </div>
          </div>
        </div>
      </div>
      \{" "}
      <h1 className="font-bold text-3xl sm:text-2xl">
        Advantages of a Starpins Business Visa
      </h1>
      <ul className="list-disc pl-6 my-4">
        <li>
          Tailored Business Solutions: Our visas are designed specifically for
          the unique needs of business travelers, ensuring a seamless
          experience.
        </li>
        <li>
          Global Networking Opportunities: Gain access to exclusive business
          events, facilitating invaluable networking and partnership
          opportunities.
        </li>
        <li>
          Market Expansion: Our business visas serve as a gateway to explore and
          penetrate new international markets, vital for business growth.
        </li>
        <li>
          Prestige and Credibility: Holding a Starpins business visa elevates
          your professional standing, signifying a serious commitment to
          international business endeavors.
        </li>
        <li>
          Extended Duration: Unlike standard tourist visas, our business visas
          offer extended stay options, giving you the flexibility to manage your
          international commitments effectively.
        </li>
      </ul>
    </div>
  );
}
